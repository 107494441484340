import { IonText } from "@ionic/react";
import classnames from "classnames";
import { PropsWithChildren } from "react";

import styles from "@components/v2/typography/Text.module.css";
import { Colors } from "@utils/colorUtils";

type Size = "4xl" | "3xl" | "2xl" | "xl" | "lg" | "md" | "sm" | "xs";
type Variant = "2xl" | "2xlSerif" | "xl" | "lg" | "md" | "sm";

type Props = {
  bold?: boolean;
  className?: string | null;
  color?: Colors;
  italic?: boolean;
  size?: Size;
  tag?: keyof JSX.IntrinsicElements;
  variant?: Variant;
};

const mapVariant: Record<Variant, string> = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  "2xl": styles["text-2xl"],
  // eslint-disable-next-line @typescript-eslint/naming-convention
  "2xlSerif": styles["text-2xl-serif"],
  lg: styles["text-lg"],
  md: styles["text-md"],
  sm: styles["text-sm"],
  xl: styles["text-xl"]
};

const Text = ({
  bold,
  children,
  className = null,
  color,
  italic,
  size,
  tag = undefined,
  variant
}: PropsWithChildren<Props>) => {
  const Tag = tag ?? "div";
  return (
    <Tag
      className={classnames(className, variant && mapVariant[variant], bold && styles.bold, italic && styles.italic)}
      style={size && { fontSize: `var(--font-size-${size})` }}
    >
      <IonText className={styles.textContent} color={color}>
        {children}
      </IonText>
    </Tag>
  );
};

export default Text;
