import { ReactElement, ReactNode, useReducer } from "react";

import { sessionContext } from "@context/Contexts";
import SessionReducer from "@reducers/sessionReducer";
import { SessionState } from "@typing/State";

const initialState: SessionState = {
  authToken: null,
  impersonateClientAuthToken: null,
  impersonateExpertAuthToken: null,
  impersonatingClient: false,
  impersonatingExpert: false,
  me: undefined,
  otpVerified: true
};

const SessionStore = ({ children }: { children: ReactNode }): ReactElement => {
  const [sessionState, dispatch] = useReducer(SessionReducer, initialState);

  return <sessionContext.Provider value={{ dispatch, sessionState }}>{children}</sessionContext.Provider>;
};

export default SessionStore;
