import logdna from "@logdna/browser";
import { Klaviyo, Loader as KlaviyoLoader } from "klaviyo-loader";
import { ReactNode, useContext, useEffect, useMemo } from "react";
import ReactGA from "react-ga4";

import { cookieConsentContext } from "@context/Contexts";
import useMe from "@hooks/useMe";
import useMyClient from "@hooks/useMyClient";

type Props = { children: ReactNode };

const AnalyticsProvider = ({ children }: Props) => {
  const me = useMe();
  const myClient = useMyClient();
  const gid = import.meta.env.VITE_GOOGLE_ANALYTICS_ID;
  const kid = import.meta.env.VITE_KLAVIYO_COMPANY_ID;
  const mezmoId = import.meta.env.VITE_MEZMO_INGESTION_KEY;

  const cookieConsentStatus = useContext(cookieConsentContext);
  const consentGiven = cookieConsentStatus.acceptsAnalyticsCookies === true;

  // The cookie consent for analytics cookies controls the services in here. If its value is null
  // it means that we don't have consent yet and therefore can't load the cookies. If it is true,
  // we should go ahead, and if it is false then we have to remove any tracking cookies that have
  // already been set and shut down the services.
  //
  // So, each of the services initialized in here needs to:
  //   * initialize itself, only if the cookie consent is affirmative
  //   * send identifying data about the user if there is one and we want to track them,
  //   * and remove cookies if consent is revoked. We don't remove the cookies directly, but
  //     rather use the APIs for the services to indicate the status of consent.

  //////////////////////////////////////////////////////////////////////////////////////
  // GA
  // GA can work without cookies in a more limited capacity, so we want to initialize
  // it regardless. But we can default the storage to denied and then turn it on when
  // the user gives consent.
  //////////////////////////////////////////////////////////////////////////////////////

  useMemo(() => {
    if (gid) {
      ReactGA.initialize(gid);

      ReactGA.gtag("consent", "default", {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        ad_storage: "denied",
        // eslint-disable-next-line @typescript-eslint/naming-convention
        analytics_storage: "denied"
      });
    }
  }, [gid]);

  useEffect(() => {
    if (me && gid && consentGiven) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      ReactGA.gtag("config", gid, { user_id: me?.id });
      // eslint-disable-next-line @typescript-eslint/naming-convention
      ReactGA.gtag("set", "user_properties", { user_type: me?.__typename });
    }
  }, [consentGiven, gid, me]);

  useEffect(() => {
    if (gid) {
      ReactGA.gtag("consent", "update", {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        analytics_storage: consentGiven ? "granted" : "denied"
      });
    }
  }, [consentGiven, gid]);

  //////////////////////////////////////////////////////////////////////////////////////
  // KLAVIYO
  //////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (myClient?.clientEmail?.email && kid && consentGiven) {
      const loader = new KlaviyoLoader(kid);
      const klaviyoPush = {
        /* eslint-disable @typescript-eslint/naming-convention */
        $email: myClient.clientEmail.email,
        $first_name: myClient.firstName,
        $last_name: myClient.lastName
        /* eslint-enable @typescript-eslint/naming-convention */
      };

      loader.load().then((klaviyo: Klaviyo) => {
        klaviyo.push(["identify", { ...klaviyoPush }]);
      });
    }
  }, [myClient, consentGiven, kid]);

  useEffect(() => {
    if (kid && cookieConsentStatus.acceptsAnalyticsCookies === false) {
      document.cookie = "__kla_off=true";
    }
  }, [cookieConsentStatus.acceptsAnalyticsCookies, kid]);

  //////////////////////////////////////////////////////////////////////////////////////
  // MEZMO
  // Mezmo doesn't cookie the user so no need to revoke anything.
  //////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (me && mezmoId) {
      logdna.addContext({
        env: import.meta.env.VITE_ENVIRONMENT_NAME,
        user: {
          userId: me.id,
          userType: me.__typename
        }
      });
    }
  }, [me, mezmoId]);

  return <>{children}</>;
};

export default AnalyticsProvider;
