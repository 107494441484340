import { App, URLOpenListenerEvent } from "@capacitor/app";
import { useEffect } from "react";
import { useHistory } from "react-router";

const AppUrlListener = () => {
  const history = useHistory();

  useEffect(() => {
    App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
      // Example url: https://app.withgrayce.com/path/to/content
      const url = new URL(event.url);
      const pathWithQuery = url.pathname + url.search;
      if (pathWithQuery) {
        history.push(pathWithQuery);
      }
      // If no match, do nothing - let regular routing logic take over
    });
  }, [history]);

  return null;
};

export default AppUrlListener;
