import { AppUpdate } from "@capawesome/capacitor-app-update";
import { IonToast } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { appUpdatedContext } from "@context/Contexts";

const AppUpdatedNotification = () => {
  const intl = useIntl();

  const { assetManifestChanged, newAppVersionAvailable } = useContext(appUpdatedContext);

  // This business is gross, the toasts won't reappear after being dismissed unless something
  // changes, so all this infrastructure is here to make sure it does.
  const [isNewAppVersionToastOpen, setNewAppVersionToastIsOpen] = useState<boolean>(false);
  const [newAppVersionToastDismissed, setNewAppVersionToastDismissed] = useState<boolean>(false);

  const handleNewAppVersionToastDismissed = () => {
    setNewAppVersionToastDismissed(true);
    setNewAppVersionToastIsOpen(false);
  };

  useEffect(() => {
    if (newAppVersionAvailable) {
      if (newAppVersionToastDismissed) {
        setNewAppVersionToastDismissed(false);
      }
      if (!isNewAppVersionToastOpen) {
        setNewAppVersionToastIsOpen(true);
      }
    }
  }, [assetManifestChanged, isNewAppVersionToastOpen, newAppVersionToastDismissed, newAppVersionAvailable]);

  return (
    <>
      <IonToast
        buttons={[
          {
            handler: () => {
              window.location.reload();
            },
            side: "end",
            text: intl.formatMessage({ id: "pages.appUpdated.web.applyButton" })
          }
        ]}
        cssClass="appUpdatedNotificationToast"
        isOpen={assetManifestChanged}
        layout="stacked"
        message={intl.formatMessage({ id: "pages.appUpdated.web.applyUpdateMessage" })}
        position="top"
      />
      <IonToast
        buttons={[
          {
            handler: () => {
              AppUpdate.openAppStore();
            },
            side: "end",
            text: intl.formatMessage({ id: "pages.appUpdated.mobile.upgradeButton" })
          }
        ]}
        cssClass="appUpdatedNotificationToast"
        isOpen={isNewAppVersionToastOpen}
        layout="stacked"
        message={intl.formatMessage({ id: "pages.appUpdated.mobile.message" })}
        onDidDismiss={handleNewAppVersionToastDismissed}
        position="top"
      />
    </>
  );
};

export default AppUpdatedNotification;
