import { AppState, App as CapacitorApp } from "@capacitor/app";
import { LocalNotifications } from "@capacitor/local-notifications";
import { ActionPerformed, PushNotifications, PushNotificationSchema } from "@capacitor/push-notifications";
import { useIonToast } from "@ionic/react";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useHistory } from "react-router";

import useMe from "@hooks/useMe";
import useMutationWithValidation from "@hooks/useMutationWithValidation";
import {
  PushNotificationPlatformEnum,
  PushNotificationTokenCreateDocument,
  PushNotificationTokenCreateMutation,
  PushNotificationTokenCreateMutationVariables
} from "@typing/Generated";
import { IconList } from "@utils/iconUtils";
import { isAndroidNativeApp, isIosNativeApp, isNativeApp } from "@utils/platformUtils";

const PushNotificationSetup = () => {
  const me = useMe();
  const registered = useRef<boolean>(false);
  const appActive = useRef<boolean>(true);
  const history = useHistory();

  const [present, dismiss] = useIonToast();

  const [createPushNotificationToken] = useMutationWithValidation<
    PushNotificationTokenCreateMutation,
    PushNotificationTokenCreateMutationVariables
  >(PushNotificationTokenCreateDocument, "pushNotificationToken");

  const platform = useMemo(() => {
    if (isIosNativeApp()) {
      return PushNotificationPlatformEnum.IOS;
    }
    return PushNotificationPlatformEnum.ANDROID;
  }, []);

  CapacitorApp.getState().then(appState => {
    appActive.current = appState.isActive;
  });

  CapacitorApp.addListener("appStateChange", (appState: AppState) => {
    appActive.current = appState.isActive;
  });

  CapacitorApp.addListener("pause", () => {
    appActive.current = false;
  });

  CapacitorApp.addListener("resume", () => {
    appActive.current = true;
  });

  const openNotificationUrl = useCallback(
    (url: string) => {
      const properUrl = new URL(url);
      const pathWithQuery = properUrl.pathname + properUrl.search;
      if (pathWithQuery) {
        // eslint-disable-next-line no-console
        console.log("+++++++ Notification pushing to", pathWithQuery);
        history.push(pathWithQuery);
      }
    },
    [history]
  );

  useEffect(() => {
    LocalNotifications.addListener("localNotificationActionPerformed", notification => {
      // eslint-disable-next-line no-console
      console.log("+++++++ Notification action performed", JSON.stringify(notification));
      const urlString = notification.notification.extra;
      if (urlString) {
        openNotificationUrl(urlString);
      }
    });

    LocalNotifications.addListener("localNotificationReceived", notification => {
      // eslint-disable-next-line no-console
      console.log("+++++++ Notification action received", JSON.stringify(notification));
    });

    return () => {
      LocalNotifications.removeAllListeners();
    };
  }, [openNotificationUrl]);

  useEffect(() => {
    if (isNativeApp() && me) {
      if (!registered.current) {
        registered.current = true;

        PushNotifications.addListener("registration", registrationData => {
          // eslint-disable-next-line no-console
          console.log("++++++ Received a push notification registration " + JSON.stringify(registrationData));
          createPushNotificationToken({
            platform,
            token: registrationData.value
          });
        });

        const registerForNotifications = async () => {
          let permStatus = await PushNotifications.checkPermissions();

          if (permStatus.receive === "prompt") {
            permStatus = await PushNotifications.requestPermissions();
          }

          if (permStatus.receive === "granted") {
            await PushNotifications.register();
          }
        };

        registerForNotifications();

        PushNotifications.addListener("registrationError", error => {
          // eslint-disable-next-line no-console
          console.log("+++++ Error on push notification registration: " + JSON.stringify(error));
        });

        PushNotifications.addListener("pushNotificationReceived", (notification: PushNotificationSchema) => {
          // This means that either:
          //   * The app is open and we got a notification. The system one will not be displayed, so we need to make our own.
          //     Show a toast!
          //   * We're on android and the app is backgrounded and gets a notification. In this case, we need to create a
          //     local notification in order to tell Android to display it.
          // eslint-disable-next-line no-console
          console.log(
            "++++++ Received a push notification received, scheduling things " + JSON.stringify(notification)
          );

          if (appActive.current) {
            present({
              buttons: [
                {
                  handler: () => {
                    openNotificationUrl(notification.data.url);
                  },
                  icon: IconList.arrowRightFromBracket
                },
                { handler: () => dismiss(), icon: IconList.xmark }
              ],
              cssClass: "pushNotificationToast",
              duration: 30_000,
              message: notification.body || notification.data.body,
              position: "top"
            });
          } else if (isAndroidNativeApp()) {
            LocalNotifications.schedule({
              notifications: [
                {
                  body: notification.body || notification.data.body,
                  extra: notification.data.url,
                  id: Math.floor(Math.random() * 2147483647),
                  title: notification.title || notification.data.title
                }
              ]
            });
          }
        });

        PushNotifications.addListener("pushNotificationActionPerformed", (notification: ActionPerformed) => {
          // eslint-disable-next-line no-console
          console.log("++++++ Received a push notification action performed " + JSON.stringify(notification));
          // Method called when tapping on a notification when the app is not open. Push notifications can contain
          // structured data about actions that you want them to take, and ideally we would implement that, but for
          // now we're just opening up the URL that we assume the notification to contain.
          openNotificationUrl(notification.notification.data.url);
        });
      }

      return () => {
        PushNotifications.removeAllListeners();
      };
    }
  }, [createPushNotificationToken, dismiss, me, openNotificationUrl, platform, present]);

  return null;
};

export default PushNotificationSetup;
